import React, {useContext, useEffect, useState} from "react";
import Cookies from "universal-cookie";

const LanguageContext = React.createContext({});

export function useLang() {
    return useContext(LanguageContext);
}
const cookies = new Cookies();

const LanguageProvider = ({ children }) => {
    const languages = {
        UA: {name: "UA", icon: "ukraine.svg", data: "🇺🇦"},
        PL: {name: "PL", icon: "poland.svg", data: "🇵🇱"},
        RU: {name: "RU", icon: "lgbt.png", data: "🇷🇺"},
    }
    const translate = {
        "mainPage": {
            "workTitle": {
                "PL": "Pracuj z nami",
                "UA": "Працюй з нами в Польщі",
                "RU": "Работай с нами в Польше"
            },
            "banner": {
                "title": {
                    "PL": "Praca na budownictwie w Polsce",
                    "UA": "Праця для будівельників в Польщі",
                    "RU": "Работа для строителей в Польше"
                },
                "text": {
                    "PL": "Znajdź pracę dla siebie i współpracuj z odpowiedzialną firmą.",
                    "UA": "Знайди вакансію для себе та працюй з відповідальною будівельною фірмою.",
                    "RU": "Найди вакансию для себя и работай с ответственной фирмой."
                }
            },
            "workText": {
                "PL": "Komanda - to polska budowlana firma.",
                "UA": "Komanda - це будівельна фірма в Польші.",
                "RU": "Komanda - это строительная фирма в Польше."
            },
            "conditions": {
                "PL": "Warunki pracy",
                "UA": "Наші умови",
                "RU": "Наши условия"
            },
            "everythingText": {
                "PL": "Zrobimy wszystkie dokumenty dla was",
                "UA": "Зробимо все потрібне для вас",
                "RU": "Сделаем для вас всё необходимое"
            },
            "about": {
                "title": {
                    "PL": "O nas",
                    "UA": "Про нас",
                    "RU": "О нас"
                },
                "buttonText": {
                    "PL": "O nas",
                    "UA": "Більше про нас",
                    "RU": "О нас"
                },
                "text": {
                    "PL": ["Komanda to firma budowlana, która pracuje na rynku Polskim z 2016 roku. My wykonujemy różne projekty zaczynając od budownictwa domu do budownictwa stadionu i zawsze rozszerzamy nasze kadry do wykonania bardziej skomplikowanych zadań.", "My dbamy o tym, aby każdy pracownik był zadowolony z wybranego pracodawcy. Uczymy, rozumiemy, dopomagamy."],
                    "UA": ["Komanda - це будівельна фірма, яка працює на Польському Ринку з 2016 року. Ми виконуємо різного роду проєкти, Починаючи від будівлі дому до будівлі стадіону і завжди Розширюємо базу спеціалістів.", " Ми дбаємо про те, аби кожен працівник був задоволений з обраного працьодавця. Вчимо, розуміємо, допомагаємо."],
                    "RU": ["Komanda- это строительная фирма, которая работает на Польском рынке с 2016 года. Мы выполняем разного рода проекты, начиная от строительства дома до строительства стадиона и всегда расширяем базу специалистов.", "Мы заботимся о том, чтобы каждый работник был удовлетворён от выбранного работодателя. Учим, понимаем, помогаем."]
                }
            }
        },
        "header": {
            "phoneText": {
                "PL": "Zatrudniamy",
                "UA": "Працевлаштовуємо",
                "RU": "Трудоустраиваем"
            },
            "items": {
                "vacancy": {
                    "PL": "Oferty pracy",
                    "UA": "Вакансії",
                    "RU": "Вакансии"
                },
                "advantages": {
                    "PL": "Benefity",
                    "UA": "Користі",
                    "RU": "Пользы"
                },
                "about": {
                    "PL": "O nas",
                    "UA": "Про нас",
                    "RU": "О нас"
                },
                "contacts": {
                    "PL": "Kontakt",
                    "UA": "Контакти",
                    "RU": "Контакты"
                }
            }
        },
        "vacancy": {
            "title": {
                "PL": "Nasze oferty pracy",
                "UA": "Наші вакансії",
                "RU": "Наши вакансии"
            },
            "text": {
                "PL": "Zobacz nasze oferty pracy, może wybierzesz coś dla siebie",
                "UA": "Продивись наші вакансії, може ти обереш щось для себе",
                "RU": "Посмотри вакансии, может ты выберешь что-то для себя"
            },
            "empty": {
                "PL": "Brak opcji",
                "UA": "Немає варіантів",
                "RU": "Нет вариантов"
            },
            "button": {
                "more": {
                    "PL": "Pobierz więcej +",
                    "UA": "Загрузи більше +",
                    "RU": "Покажи больше +"
                },
                "less": {
                    "PL": "Pokaż mniej -",
                    "UA": "Показати меньше -",
                    "RU": "Покажи меньше -"
                }
            },
            "benefits": {
                "title": {
                    "PL": "Korzyści pracy",
                    "UA": "Користі праці",
                    "RU": "Пользы работы"
                },
                "text": {
                    "PL": "Zobacz korzyści od zatrudnienia u nas",
                    "UA": "Подивись користі від працевлаштування в нас",
                    "RU": "Посмотри пользы от трудоустройства у нас"
                },
                "button": {
                    "PL": "Benefity",
                    "UA": "Користі",
                    "RU": "Пользы"
                }
            },
            "input": {
                "PL": "Szukaj",
                "UA": "Шукай",
                "RU": "Ищи"
            },
            "item": {
                "country": {
                    "PL": "Polska",
                    "UA": "Польща",
                    "RU": "Польша"
                },
                "rate": {
                    "PL": "Pełna stawka",
                    "UA": "Повна ставка",
                    "RU": "Полная ставка"
                },
                "benefits": {
                    "house": {
                        "PL": "Z noclegiem",
                        "UA": "З житлом",
                        "RU": "С жильем"
                    },
                    "language": {
                        "PL": "Bez języka polskiego",
                        "UA": "Без польської мови",
                        "RU": "Без польского языка"
                    },
                    "work": {
                        "PL": "Pracuj od teraz",
                        "UA": "Праця від зараз",
                        "RU": "Работа от сейчас"
                    },
                    "upfront": {
                        "PL": "Możliwe przedpłaty",
                        "UA": "Можливі аванси",
                        "RU": "Возможные авансы"
                    }
                }
            },
            "jobs": {
                "painter": {
                    "PL": "Malarz - Tynkarz",
                    "UA": "Маляр - Штукатур",
                    "RU": "Маляр - Штукатур"
                },
                "fitter": {
                    "PL": "Wzmacniacz",
                    "UA": "Арматурщик",
                    "RU": "Арматурщик"
                },
                "plasterer": {
                    "PL": "Gipsiarz",
                    "UA": "Гіпсокартонщик",
                    "RU": "Гипсокартонщик"
                },
                "bricklayer": {
                    "PL": "Murarz",
                    "UA": "Каменщік",
                    "RU": "Каменщик"
                }
            },
            "info": {
                "search": {
                    "PL": "Parametry",
                    "UA": "Параметри",
                    "RU": "Параметры"
                },
                "conditions": {
                    "text": {
                        "PL": "Specjalne warunki dla gotowych zespołów",
                        "UA": "Особиливі умови для готових бригад",
                        "RU": "Особые условия для готовых бригад"
                    },
                    "button": {
                        "PL": "Dowiedz się więcej",
                        "UA": "Дізнайся більше",
                        "RU": "Узнай больше"
                    }
                },
                "media": {
                    "text": {
                        "PL": "Śledź nas na portalach społecznościowych",
                        "UA": "Слідкуй за нами в соціальних мережах",
                        "RU": "Следи за нами в социальных сетях"
                    },
                    "button": {
                        "PL": "Media",
                        "UA": "Соц. мережі",
                        "RU": "Соц. сети"
                    }
                }
            }
        },
        "benefits": {
            "items": {
                "freeHousing": {
                    "RU": "Бесплатное жилье",
                    "UA": "Безкоштовне житло",
                    "PL": "Darmowe zakwaterowanie"
                },
                "payments": {
                    "RU": "Своевременные и чистые выплаты",
                    "UA": "Вчасні і прозорі виплати",
                    "PL": "Terminowe i jasne wypłaty"
                },
                "employment": {
                    "RU": "Легальное трудоустройство",
                    "UA": "Легальне працевлаштування",
                    "PL": "Legalnie zatrudnienie"
                },
                "support": {
                    "RU": "Помощь работникам",
                    "UA": "Допомога працівникам",
                    "PL": "Pomóc pracownikam"
                },
                "growth": {
                    "RU": "Возможен рост",
                    "UA": "Можливий ріст",
                    "PL": "Możliwa podwyżka"
                },
                "docs": {
                    "RU": "Делаем документы Для иностранцев",
                    "UA": "Робимо документи Для іноземців",
                    "PL": "Sporządzanie dokumentów dla cudzoziemców"
                }
            },
            "title": {
                "RU": "Пользы работы",
                "UA": "Користі праці",
                "PL": "Korzyści pracy"
            },
            "needTitle": {
                "RU": "Сделаем всё нужное для вас",
                "UA": "Зробимо все потрібне для вас",
                "PL": "Zrobimy wszystko potrzebny dla was"
            },
            "about": {
                "title": {
                    "RU": "Качественные инструменты и служебные автомобили делают работу легче и приятнее",
                    "UA": "Якісний інструмент та службові автомобілі Роблять роботу легшою та приємнішою",
                    "PL": "Jakościowy sprzęt i samochody służbowe robią pracę łatwiejszą i przyjemniejszą"
                },
                "text": {
                    "RU": ["Ми используем качественные инструменты, которые помогают нашим работникам работать легче, выполнять больше объёмы и делает работу приятнее.", "Кроме этого бригады в нашей фирме получают в распоряжение служебный автомобиль для полного комфорта."],
                    "UA": ["Ми використовуємо якісний інструмент, який допомагає Нашим працівникам працювати легше, виконувати більші Об’єми і робить працю більш приємнішою.", "Окрім того бригади в нашій фірмі отримають в розпорядження Службовий автомобіль для повного комфорту."],
                    "PL": ["My wykorzystujemy jakościowy sprzęt, który pomaga naszym pracownikam pracować łatwiej, wykonywać więcej i robić pracę przyjemniejszą.", "Pomimo tego brygady otrzymują samochód służbowy dla pełnego komfortu"]
                }
            }
        },
        "necessary": {
            "items": {
                "invitation": {
                    "title": {
                        "RU": "Приглашение",
                        "UA": "Запрошення",
                        "PL": "Oświadczenie o pracy"
                    },
                    "text": {
                        "RU": "Вы проучите договор о трудоустройств",
                        "UA": "Ми зробимо запрошення для віза",
                        "PL": "My zrobimy oświadczenie dla wizy"
                    }
                },
                "agreement": {
                    "title": {
                        "RU": "Договор",
                        "UA": "Договір",
                        "PL": "Umowa"
                    },
                    "text": {
                        "RU": "Вы проучите договор о трудоустройстве",
                        "UA": "Ви отримаєте договір про працю",
                        "PL": "Otrzymasz umowę z nami"
                    }
                },
                "insurance": {
                    "title": {
                        "RU": "Страхование",
                        "UA": "Страхуваняя",
                        "PL": "Ubezpieczenie"
                    },
                    "text": {
                        "RU": "Вы будете иметь медицинскую страховку",
                        "UA": "Ви будете мати медичне страхування",
                        "PL": "Będziesz miał ubezpieczenie"
                    }
                },
                "pit": {
                    "title": {
                        "RU": "Рассчитаный пит",
                        "UA": "Розрахований піт",
                        "PL": "Rozliczony pit"
                    },
                    "text": {
                        "RU": "Сможете вернуть налог пит",
                        "UA": "Зможете повернути податок піт",
                        "PL": "Otrzymasz rozliczony pit"
                    }
                },
                "pesel": {
                    "title": {
                        "RU": "Песель",
                        "UA": "Песель",
                        "PL": "Pesel"
                    },
                    "text": {
                        "RU": "Вы получите  идентификационный номер",
                        "UA": "Ви отримаєте індефікаційний номер",
                        "PL": "Zrobimy ci Pesel"
                    }
                },
                "card": {
                    "title": {
                        "RU": "Карта побыту",
                        "UA": "Карти побиту",
                        "PL": "Karty pobytu"
                    },
                    "text": {
                        "RU": "Помогаем получить карту побыту",
                        "UA": "Допомагаємо отрмати карту побиту*",
                        "PL": "Pomagamy otrzymać kartę pobytu*"
                    }
                }
            },
            "button": {
                "more": {
                    "PL": "Więcej korzyści pracy u nas",
                    "UA": "Більше користі праці",
                    "RU": "Больше преимуществ работы"
                },
                "less": {
                    "PL": "Mniej korzyści pracy u nas",
                    "UA": "Меньше користі праці",
                    "RU": "Меньше преимуществ работы"
                }
            }
        },
        "footer": {
            "top": {
                "title": {
                    "PL": "Jesteś zainteresowany w pracy? - skontaktuj się z nami",
                    "UA": "Зацікавленний в праці? - сконтактуйся з нами",
                    "RU": "Заинтересован в работе? - свяжись с нами"
                },
                "text": {
                    "PL": "Jeżeli ty znalazłeś pracę dla siebie - zadzwoń do nas",
                    "UA": "Якщо ти знайшов підходящу для себе вакансію телефонуй до нас.",
                    "RU": "Если ты нашёл подходящую для себя вакансию звони нам"
                }
            },
            "poland": {
                "PL": "Polska",
                "UA": "Польща",
                "RU": "Польша"
            },
            "items": {
                "other": {
                    "PL": "Inny",
                    "UA": "Інше",
                    "RU": "Другое"
                },
                "main": {
                    "PL": "Strona główna",
                    "UA": "Будівництво",
                    "RU": "Главный сайт"
                },
                "panel": {
                    "PL": "Panel pracownika",
                    "UA": "Панель працівника",
                    "RU": "Панель работника"
                }
            }
        },
        "about": {
            "title": {
                "RU": "О нас",
                "UA": "О нас",
                "PL": "O nas"
            },
            "text": {
                "RU": ["Komanda Inwestycje Sp. z.o.o.-это строительная фирма, которая работает по целой Польше. Мы выполняем строительные работы разного рода. Наши объекты обычно это - офисные здания, отели, коммерческие помещения, магазины, жилые дома и другое.", "Через большой объём работы мы используем современные технологии и способы, которые помогают выполнять роботу быстро, качественно и согласно с ожиданиями клиентов.", "Наша фирма понимает, что без добрых работников мы не сможем быть верными нашим целям и миссии. Поэтому мы заботимся о подборе работников."],
                "UA": ["Komanda Inwestycje Sp. z o.o. - це будівельна фірма, яка працює по цілій Польщі. Ми виконуємо будівельні роботи різного роду. Наші об’єкти зазвичай це - офісні будинки, отелі, комерційні приміщення, магазини, мешкальні будинки та інші.", "Через великий об’єм праці ми використовуємо сучасні технології і засоби, які допомагають виконувати роботи швидко, якісно та згідно з очеківаннями клієнтів.", "Наша фірма розуміє, що без добрих працівників ми не зможемо бути вірними нашим цілями і місії. Тому ми дбаємо про добір працівників."],
                "PL": ["Komanda Inwestycje Sp. z o.o. - to firma budowlana która pracuje na terenie całej polski. My wykonujemy pracy budowlane różnego rodzaju. Nasze projekty zazwyczaj to biurowce, hotele, komercyjne powierzchni, sklepy, mieszkaniowe budynki i inne.", "Wykorzystujemy nowoczesne technologie i zasoby, które pomagają wykonywać robotę szybko i zgodnie z oczekiwaniami klientów.", "Nasza firma rozumie, że bez dobrych pracowników ny nie możemy być takimi jakimi jesteśmy, dlatego my dbamy o nasze kadry."]
            },
            "realization": {
                "title": {
                    "PL": "Nasze realizacji",
                    "UA": "Наші реалізації",
                    "RU": "Наши реализации"
                },
                "tulip": {
                    "PL": "Warszawa, Polska",
                    "UA": "Варшава, Польща",
                    "RU": "Варшава, Польша"
                },
                "lks": {
                    "PL": "Łódź, Polska",
                    "UA": "Лодзь, Польща",
                    "RU": "Лодзь, Польша"
                },
                "biedronka": {
                    "PL": "Wrocław, Polska",
                    "UA": "Вроцлав, Польща",
                    "RU": "Вроцлав, Польша"
                }
            }
        },
        "contact": {
            "title": {
                "PL": "Kontakt",
                "UA": "Контакти",
                "RU": "Контакты"
            },
            "text": {
                "PL": "Zatrudniamy za telefonem",
                "UA": "Прaцевлаштовуємо за телефонами",
                "RU": "Трудоустраиваем по телефонам"
            },
            "phone": {
                "main": {
                    "PL": "Główny telefon",
                    "UA": "Основний телефон",
                    "RU": "Основной телефон"
                },
                "other": {
                    "PL": "Zapasowy telefon",
                    "UA": "Запасний телефон",
                    "RU": "Запасной телефон"
                }
            }
        },
        "notFound": {
            "RU": "Страница не найдена",
            "UA": "Сторінка не знайдена",
            "PL": "Strona nie została znaleziona"
        },
        "seo": {
            "description": {
                "RU": "Komanda- это строительная фирма, которая работает на Польском рынке с 2016 года. Мы выполняем разного рода проекты, начиная от строительства дома до строительства стадиона и всегда расширяем базу специалистов.",
                "UA": "Komanda - це будівельна фірма, яка працює на Польському Ринку з 2016 року. Ми виконуємо різного роду проєкти, Починаючи від будівлі дому до будівлі стадіону і завжди Розширюємо базу спеціалістів.",
                "PL": "Komanda to firma budowlana, która pracuje na rynku Polskim z 2016 roku. My wykonujemy różne projekty zaczynając od budownictwa domu do budownictwa stadionu i zawsze rozszerzamy nasze kadry do wykonania bardziej skomplikowanych zadań."
            },
            "keywords": {
                "RU": "работа,стройка,заработок,трудоустройство,работа на стройке,трудоустройство для украинцев,работа для иностранцев,работа варшава,легальное трудоустройство,бесплатное жилье,фирма варшава,работа на стройке польша, работа в польше,работа варшава для мужчин,вакансии варшава",
                "UA": "праца,будівництво,заробіток,зайнятість,праца на будівництві,працевлаштування для українців,робота для іноземців,праца варшава,легальне працевлаштування,безкоштовне житло,фірма варшава,праца на будівництві польша,праца в польщі,праца в варшаві для чоловіків,вакансії варшава",
                "PL": "praca,budownictwo,zarobki,zatrudnienie,praca na budowie,zatrudnienie ukraińca,zatrudnienie cudzoziemca,praca warszawa,legalnie zatrudnienie,darmowe zakwaterowanie,firma warszawa,praca budowa polska,praca polska,praca warszawa dla mężczyzn,oferty pracy warszawa"
            }
        }
    }

    const getBrowserLang = () => {
        if(typeof window !== 'undefined'){
            if(navigator.language === 'ru-RU' || navigator.language.toLowerCase().includes('ru'))
                return languages.RU
            if(navigator.language === 'pl-PL' || navigator.language.toLowerCase().includes('pl'))
                return languages.PL
        }
        return languages.UA
    }
    const getUserLang = () => {
        // if(typeof window !== 'undefined'){
        //     if(navigator.language === 'ru-RU' || navigator.language.toLowerCase().includes('ru'))
        //         return languages.RU
        //     if(navigator.language === 'pl-PL' || navigator.language.toLowerCase().includes('pl'))
        //         return languages.PL
        // }
        const lang = cookies.get('lang')
        if(!lang){
            const lngTmp = getBrowserLang()
            cookies.set("lang", lngTmp.name, { expires : new Date('2024-01-01')})
            return lngTmp
        }
        return languages[lang]
    }
    const [language, setLanguage] = useState(getUserLang())
    console.log(language)

    const changeLanguage = (lang) =>{
        cookies.set("lang", languages[lang].name, { expires : new Date('2024-01-01')})
        setLanguage(languages[lang])
    }



    // useEffect(() => {
    //     console.log(3)
    //     if(!lang)
    //         cookies.set("lang", getUserLang().name, { expires : new Date('2024-01-01')})
    // }, [])

    const value = {
        language,
        translate,
        changeLanguage
    }

    return (
        <LanguageContext.Provider value={value}>
            {/*{!loading && children}*/}
            {children}
        </LanguageContext.Provider>
    )
}

// export default LanguageProvider;
export default ({ element }) => <LanguageProvider>{element}</LanguageProvider>